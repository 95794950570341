.home {
    min-width: 300px;
    width: 100%;
    margin-top: 70px;
    background-color: black;

    .subtitle{
        height: fit-content;
    }

    .loadContainer {
        min-width: 300px;
        z-index: 99;
        position: fixed;
        top: 55%;
        left: 50%;
        
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: black;
        visibility: hidden;

        @media (max-width: 768px) {
            .outer, h4 {
                left: 30%;
            }
        }

        @media (min-width: 768px) and (max-width: 1200px) {
            .outer, h4 {
                left: 40%;
            }
        }

        @media (min-width: 1200px) {
            .outer, h4 {
                left: 50%;
            }
        }

        h4 {
            position: fixed;
            top: 50%;
            margin-left: 40px;
            justify-content: center;
            text-align: center;
        }

        .outer{
            position: fixed;
            top: 30%;
            .loader {
                border: 16px solid #f3f3f3;
                border-top: 16px solid #3498db;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                animation-name: spin;
                animation-duration: 5000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear; 
            }
        }
    }

    
    .MuiDataGrid-footerContainer{
        visibility: collapse;
        margin-bottom: -50px;
    }
    
    .dataTable-footer
    {
        height: 95px;
        display: flex;
        justify-content: center;
        gap: 20px;

        .pagination{
            cursor: pointer;
        }
    }

    .home-body {
        min-height: 1500px;
        padding: 50px 20px 20px 20px;
        .input {
            padding-top: 20px;
            padding-bottom: 40px;
            height: 60px;
            text-align: center;
        }

        .searched-sections{
            display: none;
        }


        @media (max-width: 415px) {
            .sections{
                height: 880px;
            }
        }

        @media (min-width: 415px) {
            .sections{
                height: 850px;
            }
        }

        .sections {
            padding: 10px;
            height: fit-content;
            margin: 50px 10px 30px 10px;
            text-align: center;
            -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);

            .buttons
            {
                display: flex;
                flex-wrap: wrap;
                .button{
                    margin-top: 10px;
                    font-size: 20px;
                    height: 30px;
                    width: fit-content;
                }

                .block{
                    background: rgba(196, 196, 196, 0.7);
                }
                margin-bottom: 20px;
            }

            .innerDatatable {
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 20px;
            }

            input {
                height: 20px;
            }

            input::placeholder {
                opacity: 0.6;
            }
        }
    }
}
.relicScorerMain{
    width: 100%;

    .relicScorerBody{
        padding: 20px;
        padding-top: 120px;
        h2, h3, h4, .input{
            height: fit-content;
            text-align: center;
        }

        .input{
            width: fit-content;
            margin: auto;
            padding: 10px;
            -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
        }

        .preview{
            display: flex;
            justify-content: center;
            gap: 20px;

            img{
                -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
                width: 100%;
                max-height: 600px;
            }
        }

        .formula{

            .explanation{
                gap: 20px;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;

                h4{
                    text-align: left;
                }
                .image{
                    -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
                    max-height: 400px;
                }
                
            @media (max-width: 1100px) {
                .text{
                    width: 100%;
                }
            }

            @media (min-width: 1100px) {
                .text{
                    width: 40%;
                }
            }
            }
            
            
        }
        
        
        .sections{
            min-height: 150px;
        }

        li{
            text-align: left;
        }
    }
}
.disclaimerMain{
    width: 100%;
    height: 820px;
    background-color: black;

    .disclaimerBody{
        max-width: 800px;
        min-width: 300px;
        margin-top: 150px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        min-height: fit-content;
        padding: 10px;
        background-image: url("../../components/image/disclaimer_bg.webp");
        background-size: cover;
        box-shadow: inset 0 0 0 2000px rgba(24, 24, 24, 0.7);
        -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 5px white);

        h2{
            text-align: center;
        }
        
        .sections{
            min-height: 150px;
        }

        li{
            text-align: left;
        }
    }
}
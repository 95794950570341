@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  margin: 0;
  color: white;
}

html,
body,
#root,
.app,
.content{
  min-width: 300px;
  background-color: black;
  user-select: none;
  height: 100%;
  width: 100%;
  font-family: 'Nunito', sans-serif;
}

a {
  color: rgb(82, 191, 255);
}

h1, h2, h3, h4{
  height: 40px;
  text-align: center;
}

p{
  line-height: 25px;
}

.characters_container
{
    padding: 20px;
    align-self: center;
    
    .characters_sections {
        .path, .element{
            width: 20px;
        }

        .school-icon{
          width: 15px;
          margin-bottom: -2px;
          background: rgb(113, 0, 206);
          padding-left: 2px;
          padding-right: 2px;
          border-radius: 5px;
        }
            
        .rarity-four-star {
            width: 35px; 
        }

        .rarity-five-star {
            width: 42px;
        }

        .image {
            object-fit: cover;
            width: 100px;
            height: 100px;
        }
        
        .name {
          height: fit-content;
          margin-bottom: 10px;
        }

        justify-content: center;
        display: flex;
        flex-flow: wrap;

        @media (max-width: 768px) {
            .characters {
                flex: 2;
            }
        }

        @media (min-width: 768px) and (max-width: 1200px) {
            .characters {
                flex: 4;
            }
        }

        @media (min-width: 1200px) {
            .characters {
                flex: 0 0 200px;
            }
        }

        .characters {
            padding-top: 10px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            text-align: center;
        }

        .characters:hover, .characters.active {
            cursor: pointer;
            scale: 120%;
            -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
            background-color: rgb(41, 41, 41);
            transition: all 0.2s ease-in-out;
        }
    }
}

.highlight-property{
  color: yellow;
  display: inline;
  margin: 0px;
  line-height: 0px;
}

.button
{
    width: 200px;

    margin-left: 20px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);
}

.topup-bot-banner-ads-container
{
  display: flex;
  align-items: center;
  justify-content: center;

  .topup-bot-banner-ads:hover{
    -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
    transition: all 0.2s ease-in-out;
}

  .topup-bot-banner-ads{
    
    max-width: 1100px;
    width: 100%;
    background-image: url("/src/components/image/topup_bot_bg.jpg");
    background-size: cover;
    z-index: 98;
    margin: 10px;
    padding: 20px;
    border-radius: 30px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  
    .topup-bot-banner-logo-container{
      background-color: white;
      max-width: 200px;
      border-radius: 20px;
      padding: 5px 10px;
      margin-bottom: 20px;
      margin-right: auto;
      display: flex;
      
      .topup-bot-banner-logo{
        width: 100%;
      }
    }


    .topup-bot-banner-button{
      background-color: white;
      height: 50px;
      width: 150px;
      border-radius: 20px;
      margin-left: auto;

      h3{
        padding-top: 10px;
        color: black;
      }
    }

    .topup-bot-banner-button:hover{
      cursor: pointer;
      scale: 110%;
      transition: all 0.2s ease-in-out;
    }
  
    h1, p{
      font-family: 'Epilogue', sans-serif;
      text-align: left;
    }
  
    h1{
      min-height: fit-content;
      letter-spacing: 0.1rem;
      font-size: 3rem;
      font-weight: 500;
    }
  
    p{
      letter-spacing: 0.05rem;
      font-size: 1rem;
    }
    
  
  .topup-bot-banner-logos{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    min-width: 300px;
    .topup-bot-banner-games{
      height: 75px;
    }
  }

    
  @media (min-width: 500px) and (max-width: 1050px) {


    h1{
      font-size: 2.5rem;
      display: inline;
    }
  
    p{
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 500px) {
    
    h1{
      text-align: center;
      font-size: 1.5rem;
    }
    
  .topup-bot-banner-logos{
    .topup-bot-banner-games{
      height: 60px;
    }
  }

    p{
      display: none;
    }
  }
  }
}

.button:hover {
    background-color: rgb(41, 41, 41);
    transition: all 0.2s ease-in-out;
}

.achievement{
  -webkit-filter: drop-shadow(0px 0px 15px #f5f821cc)
  drop-shadow(0px 0px 10px #f5f821cc);
}

.forgottenHall {
  border-radius: 50%;
  background-color: #1454c2cc;
  box-shadow:
      0 0 3px 0px #1454c2,
      0 0 30px 0px #1454c2,
      0 0 30px 9px #1454c2;
}

.memoryOfChaos{
  border-radius: 50%;
  background-color: #e65aebcc;
  box-shadow:
      0 0 3px 0px #e65aebcc,
      0 0 20px 0px #e65aebcc,
      0 0 25px 3px #e65aebcc;
}

.refresh_icon
{
  cursor: pointer;
}



.swiper-initialized{
  padding-top: 30px;
  
  .image{
      padding: 10px;
      -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 5px white);
  }
}

.swiper-pagination {
  padding-bottom: 5px;
}

.swiper{
  min-height: 250px;
  text-align: center;

  .caption{
      height: 50px;
      padding-bottom: 30px;
  }
}

.two-star {
  background: linear-gradient(0deg, rgb(82, 255, 212) 0%, rgb(1, 156, 123) 50%, rgb(0, 63, 55)100%) !important;
}

.three-star {
  background: linear-gradient(0deg, rgba(66, 115, 186, 1) 0%, rgba(60, 92, 144, 1) 50%, rgba(56, 61, 105, 1) 100%) !important;
}

.four-star {
  background: linear-gradient(0deg, rgba(145, 95, 200, 1) 0%, rgba(103, 80, 152, 1) 50%, rgba(96, 67, 105, 1) 100%) !important;
}

.five-star {
  background: linear-gradient(0deg, rgba(255, 227, 148, 1) 0%, rgba(168, 127, 94, 1) 50%, rgba(145, 91, 82, 1) 100%) !important;
}

.score-10{
  color: rgb(255, 230, 0);
}

.score-5{
  color: rgb(255, 181, 43);
}

.rank-S\+, .rank-0{
  color: rgb(252, 255, 63);
  -webkit-filter: drop-shadow(0px 0px 1px rgb(251, 255, 0)) drop-shadow(0px 0px 2px rgb(255, 187, 0)) drop-shadow(0px 0px 2px rgb(251, 255, 0));
}

.rank-S, .rank-1{
  color: rgb(255, 230, 0);
  -webkit-filter: drop-shadow(0px 0px 1px rgb(255, 187, 0)) drop-shadow(0px 0px 2px rgb(255, 187, 0));
}

.rank-A, .rank-2{
  color: rgb(255, 181, 43);
  -webkit-filter: drop-shadow(0px 0px 1px rgb(255, 181, 0)) drop-shadow(0px 0px 1px rgb(255, 181, 0));
}

.rank-B, .rank-3  {
  color: rgb(231, 123, 0);
}

.rank-C{
  color: rgb(255, 166, 125);
}

.rank-D{
  color: rgb(255, 202, 202);
}

.tooltip-container:hover{
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 10px white);
  transition: all 0.2s ease-in-out;
}

.horizontal-display-ads{
  margin: 30px;
}

.tooltip{
  text-align: center;
  .name{
    height: fit-content;
  }

  .type{
    margin-top: 10px;

    .row{
      background: gray;
      .title{
        padding: 5px;
        width: 50px;
      }
      
      .rarity{
        scale: 80%;
      }

      .subtitle{
        justify-content: center;
      }

      .image{
        width: 90px;
      }

      .value{
        font-size: 14px;
        padding: 5px;
        margin-left: 3px;
        display: flex;
        height: fit-content;
        margin-block-end: 0;
        margin-block-start: 0;
      }

      .obtain{
        .type{
          display: flex;
          justify-content: center;

          .type-image{
            margin-right: -8px;
            margin-top: -5px;
          }
        }
      }

    }
  }

  .container{
    .image{
      
    }
  }
}

.ranks{
  font-size: 20px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .rank{
      padding: 5px;
      gap: 5px;
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      display: flex;
  }
}
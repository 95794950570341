

.profile {
    .homeNavbar {
        position: relative;
        height: 100%;
        padding-bottom: 15px;

        .disclaimer{
            .cache{
                position: relative;
            }
        }
    }

    width: 100%;
    height: fit-content;

    .top_section {
        margin-top: 30px;
        justify-content: center;
        display: flex;
        flex-flow: wrap;
    
        .left-top {
            display: flex;

            img {
                width: 110px;
                height: 135px;
                -webkit-filter: drop-shadow(0px 0px 15px white);
            }

            h1{
                margin-left: 10px;
                text-align: left;
                margin-top: 0px;
            }

            h4{
                margin-left: 10px;
                text-align: left;
                height: 0px;
            }
        }

        .item {
            padding: 10px;

            .details {
                display: flex;

                h4 {
                    padding-left: 10px;
                    margin-top: 10px;
                }

                h2 {
                    padding-left: 10px;
                    margin-top: 5px;
                }

                .icon {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .top_option{
        display: flex;
        justify-content: left;

        h4{
            margin-top: 20px;
            margin-bottom: auto;
        }
    }

    .refresh{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            padding-left: 10px;
        }

        h4{
            height: fit-content;
        }
    
    }
    .tab
    {
        display: flex;
        flex-wrap: wrap;
        .button{
            width: 250px;
        }
        
    }
    
    .characters_container{
        margin-bottom: 20px !important;

    }
    .delete-button{
        background-color: rgba(255, 0, 0, 0.6);
        cursor: pointer;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: 10px;
        height: 20px;
        width: 20px;
    }
    
    .delete-button:hover, .delete-button.active {
        cursor: pointer;
        scale: 120%;
        background-color: rgba(255, 0, 0, 1);
        transition: all 0.2s ease-in-out;
    }

    .character_card_section
    {
        min-height: 1200px;
        margin: 10px;

        h2.en-us-1213{
            font-size: 18px;
        }

        .stat-height-4, .stat-height-5, .stat-height-6, .stat-height-7, .stat-height-8, .stat-height-9{
            height: 50px;
        }
    
        .stat-height-10, .stat-height-11{
            height: 42px;
        }
        
        .stat-height-12, .stat-height-13{
            height: 38px;
        }


        .title{
            padding-top: 20px;
            text-align: center;
        }
        .scrollViewer{
            -webkit-filter: drop-shadow(0px 0px 15px white);
            padding-left: 10px;
            height: 530px;
            overflow-x: visible;
            overflow-y: hidden;
            width: 100%;
            
            .displayContainer{
                position: relative;
                overflow: hidden;
                min-width: 1160px;
                max-width: 1160px;
                width: 1160px;
                height: 500px;
                display: flex;
                background-size: cover;
    
                .circle {
                    font-weight: bolder;
                    color: white;
                    text-align: center;
                    background: black;
                    -webkit-filter: drop-shadow(0px 0px 15px white);
                  }
    
                h1,
                h2,
                h3,
                h4 {
                    white-space: nowrap;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    height: fit-content;
                }
    
                .firstColumn{
                    position: absolute;
                    z-index: 9;
                    width: 70px;
    
                    h2,h4{
                        text-align: left;
                        -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                    }
    
                    .name{
                        display: flex;
                        padding-left: 20px;
                        padding-top: 30px;
                        height: 35px;
                        h2{
                            padding-right: 5px;
                        }
                    }
                    
                    .level{
                        padding-left: 20px;
                        height: 55px;
                    }
    
                    .eidolon{
                        padding-left: 20px;
                        height: 320px;
    
                        .locked {
                            filter: grayscale(100%) brightness(60%);
                        }

                        img[src=""] {
                            display: none;
                        }

                        img {
                            display: flex;
                            max-width: 40px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                        }
                    }
    
                    .uid{
                        padding-left: 20px;
                        height: 50px;
                    }
                }
    
                .secondColumn{
                    z-index: 0;
                    width: 420px;
                    height: 500px;
                    position: absolute;
                    overflow: hidden;
                    img{
                        -webkit-filter: drop-shadow(0px 0px 10px rgb(128, 128, 128));
                        margin-top: 80px;
                        margin-left: 40px;
                        width: 380px;
                    }
                }
                
                .buttons{
                    z-index: 99;
                    position: absolute;
                    visibility: collapse;
                    left: 170px;
                    top: 340px;
                    .imageButton{
                        width: 160px;
                        margin-bottom: 5px;
                        vertical-align: middle;
                        font-size: 12px;
                        background: black;
                    }
                    .scale{
                        display: flex;
                        .imageButton{
                            width: 50%;
                            min-width: 30%;
                        }
                    }

                    .navigation{
                        display: flex;
                        .imageButton{
                            width: 9.2%;
                            min-width: 9.2%;
                        }
                    }
                }

                .imageButton:hover {
                    background-color: rgb(41, 41, 41);
                    transition: all 0.2s ease-in-out;
                }

                .secondColumn:hover{
                    .buttons{
                        visibility: visible;
                    }
                }
    
                .thirdColumn{
                    position: relative;
                    z-index: 9;
                    margin-left: 370px;
                    width: 150px;
                    
                    .skill{
                        padding-left: 20px;
                        padding-top: 25px;
                        height: 220px;
                        
                        .left, .right{
                            width: 50%;
                            height: 220px;
    
                            img{
                                height: 50px;
                            }
    
                            .circle{
                                position: absolute;
                                margin-left: 15px;
                                margin-top: 35px;
                                
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                border-radius: 50%;
                                font-size: 13px;
                                z-index: 9;
                            }
                        }
                    }
    
                    .lightcone{
                        height: 210px;
                        position: relative;
    
                        .card{
                            -webkit-filter: drop-shadow(0px 0px 5px white);
                            border-radius: 15px;
                            margin: 20px;
                            img{
                                height: 90px;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }
    
                            .rarity{
                                -webkit-filter: drop-shadow(0px 0px 3px black);
                                height: 20px;
                            }
    
                            .row{
                                margin-top: 5px;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                background-color: black;
                                display: flex;
                                justify-content: center;
    
                                .circle{
                                    margin-right: 5px;
                                    width: 20px;
                                    height: 20px;
                                    line-height: 20px;
                                    border-radius: 50%;
                                    font-size: 13px;
                                    left: 10px;
                                    bottom: 10px;
                                    z-index: 2;
                                }
                            }
    
                            .name{
                                margin-left: 3px;
                                margin-right: 3px;
                                height: fit-content;
                                font-size: 12px;
                                white-space: pre-wrap;
                                text-align: center;
                                margin-top: 5px;
                                padding-bottom: 5px;
                                -webkit-filter: drop-shadow(0px 0px 3px black);
                            }
                        }
                    }
                }
    
                .forthColumn{
                    width: 230px;
    
                    .stats{
                        margin-top: 30px;
                        height: 360px;
    
                        .stat {
                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                            display: flex;
    
                            .icon {
                                margin-top: -3px;
                                height: 35px;
                            }
    
                            h2 {
                                text-align: right;
                                font-size: 18px;
                                margin-left: 8px;
                                white-space: nowrap;
                                margin-block-start: 0;
                                margin-block-end: 0;
                            }
    
                            .value.true{
                                margin-top: 0px;
                            }

                            .subvalue.true{
                                visibility: collapse;
                            }
                            
                            .value {
                                text-align: right;
                                margin-left: auto;
                                margin-top: -5px;
                                width: 80px;
    
                                .subvalue {
                                    display: flex;
                                    justify-content: flex-end;
    
                                    h4 {
                                        -webkit-filter: drop-shadow(0px 0px 5px rgb(89, 185, 89));
                                        font-size: 12px;
                                        white-space: nowrap;
                                        margin-block-start: 0;
                                        margin-block-end: 0;
                                    }
    
                                    .addition {
                                        margin-left: 3px;
                                        color: rgb(89, 185, 89);
                                    }
                                }
                            }
                        }
                    }
                }
    
                .fifthColumn{
                    padding-top: 10px;
                    
                    .relics
                    {
                        padding-left: 25px;
                        .relic{
                            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                            border-radius: 10px;
                            color: white;
                            width: 360px;
                            height: 62px;
                            margin-top: 15px;
    
                            .left{
                                width: 150px;
                                position: relative;
                                
                                .relic-set{
                                    position: relative;
                                    width: 20px;
    
                                    .relic-set-desc{
                                        position: absolute;
                                        left: 10px;
                                        top: 10px;
                                        padding: 10px 3px 10px 3px;
                                        border-radius: 5px;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
    
                                        h4{
                                        -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
                                    }
                                }
                                
                                .relic-image{
                                    height: 50px;
                                    width: 50px;
                                    margin-top: 5px;
                                    margin-left: 32px;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }
    
                                .level{
                                    position: relative;
                                    margin-left: 32px;
                                    margin-top: -27px;
                                    width: 35px;
                                    border-radius: 10px;
                                    padding: 0px 5px;
                                    font-size: 14px;
                                    background: linear-gradient(90deg, rgba(14,20,37,1) 0%, rgba(10,10,10,1) 50%, rgb(138, 110, 85) 100%);
                                }
    
    
                                .main-stat-container
                                {
                                    position: absolute;
                                    width: 50px;

                                    text-align: right;
                                    top: 5px;
                                    right: 10px;
                                    
                                    .main-stat{
                                        margin-right: 0px;
                                        width: 35px;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                    }
        
                                    .main-value{
                                        top: -10px;
                                        font-size: 20px;
                                        position: relative;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                    }
                                }
                                
                                .vertical_line {
                                    top: 5px;
                                    right: -5px;
                                    position: absolute;
                                    border-left: 1px solid white;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                    height: 50px;
                                }
    
                                .subproperties.first{
                                    right: -40px;
                                }
    
                                .subproperties.second{
                                    right: -140px;
                                }
                                
                                .subproperties{
                                    top: 3px;
                                    position: absolute;
                                    
                                    .subproperty{
                                        .sub-stat{
                                            height: 26px;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }
    
                                        .sub-value{
                                            font-size: 17px;
                                            position: absolute;
                                            margin-top: -31px;
                                            right: -35px;
    
                                            text-align: left;
                                            width: 100%;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }
                                    }
                                }
                            }      
                        }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
        }
    
        ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #00000075;
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }

    .popup-formula{
        visibility: collapse;
        z-index: 999;
        position: fixed;
        background: black;
        width: 100%;
        height: 100%;
        padding-top: 50px;
        text-align: center;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;


        h4{
            height: fit-content;
        }

        .remark{
            text-align: center;
        }

        .closeButton {
            transition: all 0.2s ease-in-out;
            position: absolute;
            top: 0px;
            right: 30px;
            font-size: 30px;
            cursor: pointer;
        }

        .closeButton:hover {
            -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white);
        }

        .button{
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            transition: none;
        }

        .formulas{
            font-size: 15px;
            justify-content: center;
            display: flex;
            gap: 5px;
        }
    }

    .portrait_section
    {
        margin: 10px;

        .stat-height-4, .stat-height-5, .stat-height-6, .stat-height-7{
            height: 55px;
        }
        .stat-height-8, .stat-height-9
        {
            height: 48px;
        }
    
        .stat-height-10{
            height: 42px;
        }
        
        .stat-height-11{
            height: 39px;
        }
        
        .stat-height-12, .stat-height-13{
            height: 37px;
        }

        .title{
            text-align: center;
        }

        .scrollViewer{
            -webkit-filter: drop-shadow(0px 0px 15px white);
            padding-left: 10px;
            height: 1120px;
            overflow-x: visible;
            overflow-y: hidden;
            width: 100%;
            display: flex;
            justify-content: center;
            
            .displayContainer{
                position: relative;
                overflow: hidden;
                width: 750px;
                min-width: 750px;
                max-width: 750px;
                height: 1100px;
                background-size: cover;
    
                .circle {
                    font-weight: bolder;
                    color: white;
                    text-align: center;
                    background: black;
                    -webkit-filter: drop-shadow(0px 0px 15px white);
                  }
    
                h1,
                h2,
                h3,
                h4 {
                    white-space: nowrap;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    height: fit-content;
                }
    
                .firstRow
                {
                    height: 490px;
                    display: flex;
                    .firstColumn{
                        position: absolute;
                        z-index: 9;
        
                        h2,h4{
                            text-align: left;
                            -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                        }
        
                        .name{
                            display: flex;
                            padding-left: 20px;
                            padding-top: 30px;
                            height: 35px;
                            h2{
                                padding-right: 5px;
                            }

                            h2.en-us-1213{
                                width: 200px;
                                white-space: pre-wrap;
                            }

                            h4.en-us-1213{
                                margin-left: -60px;
                            }
                        }
                        
                        .level{
                            padding-left: 20px;
                            height: 55px;

                            .en-us-1213{
                                padding-top: 35px;
                            }
                        }
        
                        .eidolon{
                            position: absolute;
                            top: 50px;
                            left: 240px;
                            font-size: 16px;
                            gap: 5px;

                            h4{
                                top: 55px;
                                position: absolute;
                                left: 50px;
                                -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 15px white);
                                text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                            }

                            .active{
                                -webkit-filter: brightness(70%) drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            }

                            .locked {
                                -webkit-filter: brightness(30%) drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            }

                            .eidolon-img{
                                position: absolute;
                                height: 80px;
                                width: 90px;
                            }
                        }
        
                        .uid{
                            padding-left: 20px;
                            margin-top: -25px;
                            height: 20px;

                            .en-us-1213{
                                padding-top: 35px;
                            }
                        }
                    }
        
                    .secondColumn{
                        width: 380px;
                        height: 470px;
                        position: absolute;
                        overflow: hidden;
                        img{
                            -webkit-filter: drop-shadow(0px 0px 10px rgb(128, 128, 128));
                            margin-top: 100px;
                            width: 380px;
                        }
                    }

                    .buttons{
                        padding: 10px;
                        visibility: collapse;
                        z-index: 99;
                        position: absolute;
                        left: 0px;
                        top: 340px;
                        .imageButton{
                            margin-bottom: 10px;
                            vertical-align: middle;
                            font-size: 15px;
                            background: black;
                        }

                        .scale{
                            display: flex;
                            .imageButton{
                                width: 50%;
                                min-width: 30%;
                            }
                        }

                        .navigation{
                            display: flex;
                            .imageButton{
                                width: 12%;
                                min-width: 12%;
                            }
                        }
                    }

                    .imageButton:hover {
                        background-color: rgb(41, 41, 41);
                        transition: all 0.2s ease-in-out;
                    }

                    .secondColumn:hover{
                        .buttons{
                            visibility: visible;
                        }
                    }
        
                    .thirdColumn{
                        position: relative;
                        margin-left: 340px;
                        width: 150px;
                        
                        .skill{
                            padding-left: 20px;
                            padding-top: 25px;
                            height: 220px;
                            
                            .left, .right{
                                width: 50%;
                                height: 220px;
        
                                img{
                                    height: 50px;
                                }
        
                                .circle{
                                    position: absolute;
                                    margin-left: 15px;
                                    margin-top: 35px;
                                    
                                    width: 20px;
                                    height: 20px;
                                    line-height: 20px;
                                    border-radius: 50%;
                                    font-size: 13px;
                                    z-index: 9;
                                }
                            }
                        }
        
                        .lightcone{
                            position: relative;
        
                            .card{
                                -webkit-filter: drop-shadow(0px 0px 5px white);
                                height: fit-content;
                                border-radius: 15px;
                                margin: 20px;
                                img{
                                    height: 66px;
                                    display: block;
                                }
        
                                .rarity{
                                    -webkit-filter: drop-shadow(0px 0px 3px black);
                                    height: 15px;
                                }
        
                                .row{
                                    margin-top: 5px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    background-color: black;
                                    display: flex;
                                    justify-content: center;
        
                                    .circle{
                                        -webkit-filter: drop-shadow(0px 0px 3px white);
                                        margin-right: 5px;
                                        width: 20px;
                                        height: 20px;
                                        line-height: 20px;
                                        border-radius: 50%;
                                        font-size: 13px;
                                        left: 10px;
                                        bottom: 10px;
                                        z-index: 2;
                                    }
                                }
        
                                .name{
                                    padding-top: 5px;
                                    padding-left: 4px;
                                    padding-right: 4px;
                                    padding-bottom: 3px;
                                    font-size: 12px;
                                    white-space: pre-wrap;
                                    text-align: center;
        
                                    -webkit-filter: drop-shadow(0px 0px 3px black);
                                }
                            }
                        }
                    }
        
                    .forthColumn{
                        width: 230px;
        
                        .stats{
                            margin-top: 30px;
                            height: 360px;
        

                            .stat {
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                display: flex;
        
                                .icon {
                                    margin-top: -3px;
                                    height: 35px;
                                }
        
                                h2 {
                                    text-align: right;
                                    font-size: 18px;
                                    margin-left: 8px;
                                    white-space: nowrap;
                                    margin-block-start: 0;
                                    margin-block-end: 0;
                                }
        
                                .value.true{
                                    margin-top: 0px;
                                }

                                .subvalue.true{
                                    visibility: collapse;
                                }

                                .value {
                                    text-align: right;
                                    margin-left: auto;
                                    margin-top: -5px;
                                    width: 80px;
        

    
                                    .subvalue {
                                        display: flex;
                                        justify-content: flex-end;
        
                                        h4 {
                                            -webkit-filter: drop-shadow(0px 0px 5px rgb(89, 185, 89));
                                            font-size: 12px;
                                            white-space: nowrap;
                                            margin-block-start: 0;
                                            margin-block-end: 0;
                                        }
        
                                        .addition {
                                            margin-left: 3px;
                                            color: rgb(89, 185, 89);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .secondRow{    

                    .top-summary
                    {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        
                        display: flex;
                        width: fit-content;
                        margin-top: -20px;
                        margin-bottom: 0px;
                        background: rgba(255, 255, 255, 0.5);
                        border-radius: 5px;
                        padding: 5px 20px;
                        gap: 10px;
                    }

                    .relic-summary-title
                    {
                        text-align: center;
                        text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;

                    }

                    .relic-summary-score{
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                    }

                    .relics
                    {
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;

                        .relic{
                            display: flex;
                            flex-wrap: wrap;
                            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                            border-radius: 10px;
                            color: white;
                            width: 230px;
                            height: 270px;
                            margin-top: 15px;
                            margin-left: 10px;
                            margin-right: 10px;
    
                            .relicTop{
                                position: relative;
                                
                                .relic-set{
                                    position: relative;
                                    width: 20px;
    
                                    .relic-set-desc{
                                        position: absolute;
                                        left: 10px;
                                        top: 10px;
                                        padding: 10px 3px 10px 3px;
                                        border-radius: 5px;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
    
                                        h4{
                                        -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
                                    }
                                }
                                
                                .relic-image{
                                    height: 48px;
                                    width: 48px;
                                    margin-top: 5px;
                                    margin-left: 32px;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }
    
                                .level{
                                    position: relative;
                                    margin-left: 32px;
                                    margin-top: -25px;
                                    width: 35px;
                                    border-radius: 10px;
                                    padding: 0px 5px;
                                    font-size: 14px;
                                    background: linear-gradient(90deg, rgba(14,20,37,1) 0%, rgba(10,10,10,1) 50%, rgb(138, 110, 85) 100%);
                                }
    
    
                                .main-stat-container
                                {
                                    position: absolute;
                                    width: 50px;

                                    text-align: right;
                                    top: 5px;
                                    left: 85px;
                                    
                                    .inner {
                                        display: flex;

                                        .main-stat{
                                            margin-right: 0px;
                                            width: 35px;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }

                                        .main-value{
                                            position: relative;
                                            margin-top: 0px;
                                            padding-left: 10px;
                                            text-align: left;
                                            font-size: 22px;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }
                                    }
        
                                    .description {
                                        padding-left: 5px;
                                        text-align: left;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                    }
                                }
                            }    
                            
                            .horizontal_line{
                                margin-top: 10px;
                                background: white;
                                position: relative;
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                height: 1px;
                                margin-left: 5px;
                                margin-right: 5px;
                                width: 100%;
                            }

                            .relic-score
                            {
                                margin-top: 5px;
                                margin-bottom: -13px;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                gap: 20px;
                                text-align: center;

                                .score-title{
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }

                                .value{
                                    font-size: 30px;
                                    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                    #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                                }
                            }

                            .subproperties{
                                padding-top: 5px;
                                width: 100%;
                                height: 100%;
                                
                                .subproperty {
                                    width: 100%;
                                    padding-left: 5px;
                                    padding-bottom: 5px;
                                    display: flex;
                                    
                                    .inner {
                                        width: 55%;
                                        display: flex;
    
                                        .description {
                                            padding-left: 5px;
                                            padding-top: 2px;
                                            -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
    
                                        .sub-stat {
                                            height: 26px;
                                            -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
                                    }
    
                                    .sub-value-container
                                    {
                                        padding-top: 2px;
                                        display: flex;
                                        font-size: 17px;
                                        text-align: right;
                                        gap: 5px;
                                        -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);

                                        .empty-enhancement{
                                            padding-left: 3px;
                                            padding-right: 3px;
                                            visibility: hidden;
                                        }

                                        .enhancement{
                                            padding-left: 3px;
                                            padding-right: 3px;
                                            border-radius: 3px;
                                            background-color: rgb(89, 185, 89);
                                            text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                                #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
        }
    
        ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #00000075;
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }

    .mobile_section
    {
        min-height: 1460px;
        margin: 10px;

        .stat-height-4, .stat-height-5, .stat-height-6, .stat-height-7, .stat-height-8, .stat-height-9{
            height: 35px;
            h2{
                font-size: 10px;
                margin-left: 8px;
                white-space: nowrap;
                margin-block-start: 0;
                margin-block-end: 0;
            }
        }

        .stat-height-10, .stat-height-11, .stat-height-12, .stat-height-13{
            h2{
                font-size: 8px;
                margin-left: 8px;
                white-space: nowrap;
                margin-block-start: 0;
                margin-block-end: 0;
            }
        }
        .stat-height-9{
            height: 32px;
        }
        .stat-height-10{
            height: 28px;
        }
        .stat-height-11{
            height: 26px;
        }
        .stat-height-12, .stat-height-13{
            height: 24px;
        }

        .title{
            text-align: center;
        }

        .scrollViewer{
            -webkit-filter: drop-shadow(0px 0px 15px white);
            height: 710px;
            overflow-x: visible;
            overflow-y: hidden;
            width: 100%;
            display: flex;
            justify-content: center;
            
            .displayContainer{
                position: relative;
                overflow: hidden;
                width: 370px;
                min-width: 370px;
                max-width: 370px;
                height: 690px;
                background-size: cover;
    
                .circle {
                    font-weight: bolder;
                    color: white;
                    text-align: center;
                    background: black;
                    -webkit-filter: drop-shadow(0px 0px 15px white);
                  }
    
                h1,
                h2,
                h3,
                h4 {
                    white-space: nowrap;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    height: fit-content;
                }
    
                .firstRow
                {
                    height: 295px;
                    display: flex;
                    .firstColumn{
                        position: absolute;
                        z-index: 9;

                        h2,h4 {
                            text-align: left;
                        }
        
                        .name{
                            -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            font-size: 12px;
                            display: flex;
                            padding-left: 10px;
                            padding-top: 10px;
                            height: 25px;
                            h2{
                                padding-right: 5px;
                            }

                            h2.en-us-1213{
                                width: 110px;
                                font-size: 12px;
                                white-space: pre-wrap;
                            }

                            h4.en-us-1213{
                                margin-left: -30px;
                            }
                        }
                        
                        .level{
                            -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            font-size: 12px;
                            padding-left: 10px;
                            height: 20px;

                            .en-us-1213{
                                padding-top: 10px;
                            }
                        }

                        .uid{
                            -webkit-filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            font-size: 12px;
                            padding-left: 10px;
                            
                            .en-us-1213{
                                padding-top: 10px;
                            }
                        }
        
                        .eidolon{
                            position: absolute;
                            top: 5px;
                            left: 180px;
                            font-size: 12px;
                            gap: 5px;

                            h4{
                                top: 35px;
                                position: absolute;
                                left: 24px;
                                -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 15px white);
                                text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                            }

                            .active{
                                -webkit-filter: brightness(90%) drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            }

                            .locked {
                                -webkit-filter: brightness(30%) drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                            }

                            .eidolon-img{
                                position: absolute;
                                top: 3px;
                                height: 40px;
                                width: 50px;
                            }
                        }
                    }
        
                    .secondColumn{
                        width: 250px;
                        height: 300px;
                        position: absolute;
                        overflow: hidden;
                        img{
                            -webkit-filter: drop-shadow(0px 0px 20px rgb(128, 128, 128));
                            padding-top: 0px;
                            width: 230px;
                        }
                    }

                    .buttons{
                        z-index: 99;
                        position: absolute;
                        visibility: collapse;
                        left: 10px;
                        top: 210px;

                        .imageButton{
                            height: 20px;
                            padding-top: 3px;
                            padding-bottom: 0px;
                            margin-bottom: 3px;
                            vertical-align: middle;
                            font-size: 12px;
                            background: black;
                        }

                        .scale{
                            display: flex;
                            .imageButton{
                                width: 50%;
                                min-width: 30%;
                            }
                        }

                        .navigation{
                            display: flex;
                            .imageButton{
                                width: 12%;
                                min-width: 12%;
                            }
                        }
                    }

                    .imageButton:hover {
                        background-color: rgb(41, 41, 41);
                        transition: all 0.2s ease-in-out;
                    }


                    .lightconeColumn{
                        z-index: 0;
                        position: absolute;
                        .lightcone{
                            z-index: 0;
                            position: absolute;
                            left: 30px;
                            top: 220px;
        
                            .card{
                                -webkit-filter: drop-shadow(0px 0px 5px white);
                                border-radius: 15px;
                                display: flex;
                                img{
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                                .lightcone-image{
                                    position: relative;
                                    left: 10px;
                                    height: 60px;
                                    width: 60px;
                                }
        
                                .right{
                                    width: 120px;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                    .rarity{
                                        padding-top: 2px;
                                        margin-left: 40px;
                                        -webkit-filter: drop-shadow(0px 0px 3px black);
                                        height: 13px;
                                    }

                                    .row{
                                        height: 20px;
                                        margin-top: 3px;
                                        -webkit-filter: drop-shadow(0px 0px 3px black);
                                        display: flex;
                                        justify-content: center;
                                        font-size: 12px;
            
                                        .circle{
                                            -webkit-filter: drop-shadow(0px 0px 3px white);
                                            margin-right: 5px;
                                            width: 15px;
                                            height: 15px;
                                            line-height: 15px;
                                            border-radius: 50%;
                                            left: 10px;
                                            bottom: 10px;
                                            z-index: 2;
                                        }
                                    }

                                    .name{
                                        margin-left: 3px;
                                        margin-right: 3px;
                                        font-size: 12px;
                                        white-space: pre-wrap;
                                        vertical-align: middle;
                                        text-align: center;
                                        -webkit-filter: drop-shadow(0px 0px 3px black);
                                    }
                                }
                            }
                        }
                    }
        
                    .thirdColumn{
                        margin-left: 240px;
                        z-index: 9;
                        
                        .skill{
                            padding-top: 3px;
                            height: 220px;
                            
                            .left, .right{
                                width: 50%;
                                height: 220px;
        
                                img{
                                    height: 35px;
                                }
        
                                .circle{
                                    position: absolute;
                                    margin-left: 10px;
                                    margin-top: 23px;
                                    
                                    width: 15px;
                                    height: 15px;
                                    line-height: 15px;
                                    border-radius: 50%;
                                    font-size: 10px;
                                    z-index: 9;
                                }
                            }
                        }
                    }
        
                    .forthColumn{
                        width: 200px;
                        h2,h4{
                            text-align: left;
                        }
        
                        .stats{
                            margin-top: 10px;
        
                            .stat {
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                width: 100%;
                                display: flex;

                                .left{
                                    .icon {
                                        margin-top: -3px;
                                        height: 20px;
                                        width: 20px;
                                    }
                                }
                                
                                .right-top{
                                    h4 {
                                        font-size: 12px;
                                        white-space: nowrap;
                                        margin-block-start: 0;
                                        margin-block-end: 0;
                                    }

                                    .subvalue{
                                        gap: 4px;
                                        display: flex;

                                        .addition{
                                            color: rgb(89, 185, 89);
                                            -webkit-filter: drop-shadow(0px 0px 5px rgb(89, 185, 89));
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }

                .middleRow{
                    height: 50px;
                    .eidolon{
                        display: flex;
                        justify-content: center;
    
                        .locked {
                            filter: grayscale(100%) brightness(30%);
                        }

                        img[src=""] {
                            display: none;
                        }

                        img {
                            margin: 2px;
                            display: flex;
                            height: 40px;
                            width: 40px;
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                    }
                }
    
                .secondRow{    
                    .top-summary
                    {
                        
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        
                        display: flex;
                        justify-content: center;
                        width: fit-content;
                        margin-top: -15px;
                        margin-bottom: 20px;

                        background: rgba(255, 255, 255, 0.5);
                        border-radius: 5px;
                        padding: 5px 20px;
                        font-size: 12px;
                        gap: 10px;
                        text-align: center;
                        text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;

                        .relic-summary-score{
                            gap: 10px;
                            display: flex;
                            justify-content: center;
                        }
                    }

                    .relics
                    {
                        margin-top: 40px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 2px;

                        .relic{
                            display: flex;
                            flex-wrap: wrap;
                            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                            border-radius: 10px;
                            color: white;
                            width: 116px;
                            height: 160px;
                            margin-top: 10px;
                            margin-left: 3px;
                            margin-right: 3px;

                            .relicTop{
                                position: relative;
                                height: 40px;
                                width: 100%;
                                
                                .relic-set{
                                    position: relative;

                                    .relic-set-desc{
                                        position: absolute;
                                        left: 5px;
                                        top: 10px;
                                        padding: 5px 2px 5px 2px;
                                        border-radius: 5px;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
    
                                        h4{
                                            font-size: 12px;
                                            -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
                                    }
                                }
                                
                                .relic-image{
                                    padding-top: 5px;
                                    width: 32px;
                                    height: 32px;
                                    margin-left: 19px;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }
    
                                .level{
                                    position: relative;
                                    margin-left: 20px;
                                    margin-top: -18px;
                                    width: 20px;
                                    border-radius: 10px;
                                    padding: 0px 5px;
                                    font-size: 10px;
                                    background: linear-gradient(90deg, rgba(14,20,37,1) 0%, rgba(10,10,10,1) 50%, rgb(138, 110, 85) 100%);
                                }
    
                                .main-stat-container
                                {
                                    position: absolute;
                                    text-align: right;
                                    top: 5px;
                                    left: 52px;
                                    
                                    .inner {
                                        display: flex;

                                        .main-stat{
                                            margin-right: 0px;
                                            width: 20px;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }

                                        .main-value{
                                            position: relative;
                                            margin-top: 0px;
                                            text-align: left;
                                            font-size: 13px;
                                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                        }
                                    }
        
                                    .description {
                                        padding-left: 3px;
                                        text-align: left;
                                        font-size: 10px;
                                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                    }
                                }
                            }    
                            
                            .horizontal_line{
                                background: white;
                                position: relative;
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                height: 1px;
                                margin-top: 4px;
                                margin-left: 5px;
                                margin-right: 5px;
                                width: 100%;
                            }

                            .relic-score
                            {
                                margin-top: 2px;
                                margin-bottom: -3px;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                gap: 15px;
                                text-align: center;
                                font-size: 12px;

                                .score-title{
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }

                                .value{
                                    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                    #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                                }
                            }

                            .subproperties{
                                padding-top: 5px;
                                width: 100%;
                                height: 100%;
                                
                                .subproperty {
                                    width: 100%;
                                    padding-left: 5px;
                                    display: flex;
                                    
                                    .inner {
                                        width: 45%;
                                        display: flex;
    
                                        .description {
                                            font-size: 10px;
                                            -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
    
                                        .sub-stat {
                                            -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                        }
                                    }
    
                                    .sub-value-container
                                    {
                                        height: 23px;
                                        display: flex;
                                        text-align: right;
                                        gap: 8px;
                                        -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);

                                        .sub-value{
                                            font-size: 10px;
                                        }
                                        
                                        .empty-enhancement{
                                            margin-top: 0px;
                                            font-size: 10px;
                                            padding-left: 3px;
                                            padding-right: 3px;
                                            visibility: hidden;
                                        }

                                        .enhancement{
                                            margin-top: 0px;
                                            font-size: 9px;
                                            padding-top: 2px;
                                            padding-left: 3px;
                                            padding-right: 3px;
                                            border-radius: 3px;
                                            background-color: rgb(89, 185, 89);
                                            text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                                #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
        }
    
        ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #00000075;
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    

    .options{
        h4{
            text-align: left;
        }
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        gap: 20px;

        input{
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .sketch-picker{
            margin-top: -15px;
            height: 190px;
            scale: 80%;
            overflow: hidden;
            margin-bottom: -20px;
        }

        .option{
            min-width: 100px;
            padding-left: 10px;
            padding-right: 10px;
            -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
        }

        @media (max-width: 550px) {
            .option{
                width: 100%;
            }
        }

        @media (min-width: 550px) and (max-width: 768px) {
            .option{
                width: 40%;
            }
        }

        @media (min-width: 768px) {
            .option{
                width: fit-content;
            }
        }
    }

    @media (max-width: 550px) {
        .options{
            visibility: collapse;
        }

        .display-ads{
            margin-top: -400px;
        }
    }

    @media (min-width: 550px) and (max-width: 768px) {
        .options{
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .options{
            width: fit-content;
        }
    }
    .button{
        position: relative;
        display: flex;
        min-width: 100px;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
    }
}
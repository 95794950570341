.starry-night{
    background: linear-gradient(to bottom, #13083a 0%, #000000 100%);
}

.starry-night{
    
    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

    $starFieldWidth: 2560;
    $starFieldHeight: 7060;
    $starStartOffset: 1000px;

    $starOneScrollDuration: 500s;
    $starTwoScrollDuration: 525s;
    $starThreeScrollDuration: 575s;
    $numStarOneStars: 1700;
    $numStarTwoStars: 700;
    $numStarThreeStars: 200;
    $numComet: 10;
    @function create-stars($n) {
        $stars: "#{random($starFieldWidth)}px #{random($starFieldHeight)}px #FFF";
      
        @for $i from 2 through $n {
          $stars: "#{$stars} , #{random($starFieldWidth)}px #{random($starFieldHeight)}px #FFF";
        }
        @return unquote($stars);
      }

    @keyframes animStar {
        from {
          transform: translateY(0px);
        }
        to {
          transform: translateY(-#{$starFieldHeight}px)
            translateX(-#{$starFieldWidth}px);
        }
      }

      @mixin star-template($numStars, $starSize, $scrollSpeed) {
        z-index: 10;
        width: $starSize;
        height: $starSize;
        border-radius: 50%;
        background: transparent;
        box-shadow: create-stars($numStars);
        animation: animStar $scrollSpeed linear infinite;
        &:after {
          content: " ";
          top: -$starStartOffset;
          width: $starSize;
          height: $starSize;
          border-radius: 50%;
          position: absolute;
          background: transparent;
          box-shadow: create-stars($numStars);
        }
      }
      
      @mixin shooting-star-template($numStars, $starSize, $speed) {
        z-index: 10;
        width: $starSize;
        height: $starSize + 80px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        animation: animShootingStar $speed linear infinite;
      }
      .stars {
        @include star-template($numStarOneStars, 1px, $starOneScrollDuration);
      }
      .stars2 {
        @include star-template($numStarTwoStars, 2px, $starTwoScrollDuration);
      }
      .stars3 {
        @include star-template($numStarThreeStars, 3px, $starThreeScrollDuration);
      }
}
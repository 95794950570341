.footer{
    background-color: black;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 300px;
    -webkit-box-shadow: 2px -4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px -4px 10px 1px rgba(201, 201, 201, 0.47);
    
    .sections{
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
    }

    h2{
        word-wrap: break-word;
        text-align: center;
        opacity: 0.6;
        color: white;
    }

    
    h2:hover{
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s;
    }

    .logo{
        opacity: 0.6;
        height: 40px;
        width: 120px;
    }

    .logo:hover{
        opacity: 1;
        transition: opacity 0.3s;
    }
}
.homeNavbar {
    min-width: 300px;
    height: 67px;
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    
    .closeButton {
        transition: all 0.2s ease-in-out;
        font-size: 30px;
        cursor: pointer;
    }

    .closeButton:hover {
        -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 3px white);
    }

    .popUpContainer {

        .sectionButton{
            transition: all 0.2s ease-in-out;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 10px;
        }
        
        .sectionButton:hover{
            background-color: rgb(192, 192, 192);
        }

        .closeButton{
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 10px;
            height: 50px;
            width: 50px;
            color: white;
        }
        
        .closeButton:hover{
            -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white) ;
        }

        margin-top: -70px;
        padding-top: 150px;
        position: fixed;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.95);
        width: 100%;
        height: 100%;
        
        .details{
            margin-bottom: 50px;
        }

        p{
            width: 200px;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
            transition: "all 0.2s ease";
            padding: "10px";
            text-align: center;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            background-color: white;
            color: black;
        }

        .logo{
            margin-bottom: -5px;
            padding-right: 5px;
            height: 25px;
        }
    }

    .cache{
        margin-left: 10px;
        position: fixed;
        width: 100%;
        
        .cacheSections {
            display: flex;
            flex-wrap: wrap;
            .cacheUsers {
                -webkit-box-shadow: 2px 4px 10px 5px rgba(82, 191, 255, 0.47);
                box-shadow: 2px 4px 10px 5px rgba(82, 191, 255, 0.47);
                background-color: black;
                display: flex;
                h3{
                    position: absolute;
                }
    
                h4 {
                    padding: 10px;
                    height: 20px;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    padding-right: 15px;
                }
    
                .closeButton{
                    font-size: 20px;
                    padding-right: 10px;
                }
    
                a{
                    color: white;
                }
    
                .cross:hover{
                    color:red;
                }
    
            }
    
            .cacheUsers:hover {
                cursor: pointer;
                background-color: rgb(54, 54, 54);
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .top {
        position: relative;
        background-image: url("..\\image\\navbar.png");
        background-color: rgba(0,0,0,1);
        display: flex;
        justify-content: space-between;

        .topRight {
            display: flex;
            align-self: center;
            padding-right: 10px;
            gap: 10px;

            .sectionButton {
                background-image: url("..\\image\\button.png");
                background-size: 100% 100%; 
                padding: 5px 10px 5px;
                font-size: 18px;
                cursor: pointer;
                color: white;
                width: 100px;
                text-align: center;

                &.active {
                    font-weight: bold;
                    font-size: 16px;
                    text-decoration: underline;
                    text-decoration-thickness: 3px;
                    text-underline-offset: 6px;
                }

                .logo{
                    padding-right: 5px;
                    height: 25px;
                    width: 25px;
                    -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white);
                }
            }

            .language{
                display: flex;
                margin-top: auto;
                margin-bottom: auto;
                justify-content: center;
            }

            .sectionButton:hover{
                scale: 110%;
                transition: all 0.2s ease-in-out;
            }

        }
        
        .mainmenu{
            visibility: collapse;
            position: relative;
            top: 43px;
            left: -30px;
            z-index: 99;


            .innerMenu{
                width: 100%;
                position: fixed;
                list-style-type: none;
                
                li:hover{
                    scale: 110%;
                    transition: all 0.2s ease-in-out;
                }

                li{
                    color:white;
                    background-image: url("..\\image\\button.png");
                    background-size: 100% 100%; 
                    padding: 5px 10px 5px;
                    font-size: 18px;
                    cursor: pointer;
                    color: white;
                    max-width: 100px;
                    text-align: center;
    
                    &.active {
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: underline;
                        text-decoration-thickness: 3px;
                        text-underline-offset: 6px;
                    }
                }
            }
        }


        .hamburger{
            display: none;
            color: white;
            
            .icon{
                transform: scale(1.5);
                margin-top: 30px;
            }

            .logo{
                width: 25px;
                height: 25px;
            }

            .sectionButton:hover{
                scale: 110%;
                transition: all 0.2s ease-in-out;
            }
            
            .icon:hover{
                cursor: pointer;
                scale: 110%;
                transition: all 0.2s ease-in-out;
            }
        }
    
        .topLeft{
            padding-left: 10px;
            display: flex;
            align-items: center;

            .logo{
                padding: 10px;
                height: 40px;
                width: 120px;
                content: url("../image/logo_icon.png");
            }
            
            .logo:hover{
                opacity: 0.6;
                transition: opacity 0.3s;
            }
        }


        @media screen and (max-width: 450px) {
            .topLeft{
                .logo{
                    margin-top: 5px;
                    height: 40px;
                    width: 40px;
                    content: url("../image/logo.png");
                }
            }
        }

        @media screen and (max-width: 1150px) {

            .topRight{
                display: none;
            }

            .hamburger{
                display: flex;
                align-self: center;
                margin-right: 30px;
                gap: 10px;

                .sectionButton{
                    background-image: url("..\\image\\button.png");
                    background-size: 100% 100%; 
                    padding: 5px 10px 5px;
                    font-size: 18px;
                    margin-right: 5px;
                    cursor: pointer;
                    color: white;
                    width: 100px;
                    text-align: center;
    
                    .logo{
                        margin-bottom: -5px;
                        padding-right: 10px;
                        height: 25px;
                        -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white);
                    }
                }

                p{
                    color: black;
                    font-size: 18px;
                }
            }
        }
    }

}

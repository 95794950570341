.singleCharacter {
    width: 100%;
    margin-top: 70px;
    background-color: black;

    .singleCharacterContainer {
        padding-top: 50px;
        width: 100%;
        min-height: 2500px;
        padding-bottom: 50px;

        .divider {
            padding-top: 60px;
        }


        .title {
            padding-top: 20px;
            margin-top: 0px;
            margin-bottom: 20px;
            font-size: 40px;
            text-align: center;
        }

        .top {
            .title{
                padding-top: 0px;
                height: fit-content;
                width: 50%;
                text-align: left;
            }

            position: relative;
            outline: solid 2px white;
            outline-offset: -10px;

            background: linear-gradient(90deg, rgb(0, 50, 100) 0%, rgba(110, 141, 190, 1) 50%, rgba(154, 194, 191, 1) 100%);
            border-top-right-radius: 10px;
            padding: 20px;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            max-width: 900px;

            .property {
                display: flex;

                .element,
                .path {
                    width: 50px;
                    height: 50px;
                }

                padding-bottom: 20px;
            }

            .rarity {
                padding-bottom: 20px;
            }

            .story {
                position: relative;
                background-color: rgba(66, 93, 151, 0.75);
                border-radius: 10px;
                width: fit-content;
                padding: 10px;
                z-index: 9;

                h4 {
                    font-size: 13px;
                    padding-right: 15px;
                    height: fit-content;
                }

                padding-bottom: 20px;
            }

            .image {
                position: absolute;
            }

            @media (max-width: 768px) {
                h2{
                    font-size: 20px;
                }

                .image {
                    width: 50%;
                    right: 0px;
                    top: 20px;
                }

                .story {
                    h4 {
                        font-size: 13px;
                    }
                }
            }

            @media (min-width: 768px) and (max-width: 1200px) {
                h2{
                    font-size: 30px;
                }

                .image {
                    width: 45%;
                    right: -50px;
                    top: -50px;
                }

                .story {
                    h4 {
                        font-size: 15px;
                    }
                }
            }

            @media (min-width: 1200px) {
                h2{
                    font-size: 40px;
                }

                .image {
                    width: 50%;
                    right: -80px;
                    top: -70px;
                }

                .story {
                    h4 {
                        font-size: 18px;
                    }
                }
            }
        }

        .secondTop {
            max-width: 1750px;
            padding-top: 20px;
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-wrap: wrap;

            .full-row{
                width: 100% !important;
            }
            @media (max-width: 770px) {
                .row {
                    width: 100%;
                }
            }

            @media (min-width: 770px){
                .row {
                    width: 40%;
                }
            }

            .row {
                padding: 20px;
                margin: 10px;
                -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);
                
                h2, h3, h4{
                    height: fit-content;
                    margin-top: 3px;
                    margin-bottom: 3px;
                }
                
                .subtitle{
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .materials{
                    margin-top: 5px;
                    margin-left: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    .material{

                        .value{
                            position: absolute;
                            border-radius: 10px;
                            width: 45px;
                            background: rgba(0, 0, 0, 0.7);
                            margin-top: -22px;
                            margin-left: 2.5px;
                            font-size: 11px;
                        }

                        img{
                            border-radius: 10px;
                            margin-right: 5px;
                            height: 50px;
                            width: 50px;
                        }
                    }
                }

                .team{
                    -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);
                    padding: 10px;
                    margin-bottom: 10px;
                    h3{
                        padding-bottom: 10px;
                    }

                    .team-container{
                        justify-content: space-evenly;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .member-container{
                            padding: 5px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-evenly;
    
                            .member-details{
                                padding-right: 5px;
                                img{
                                    width: 50px;
                                    height: 58px;
                                }
                                h4{
                                    margin-top: -5px;
                                    word-wrap: normal;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .main-stat-container
                {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .main-stat{
                        flex: 0 0 21%;
                        margin: 5px;

                        -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);

                        .type{
                            height: 50px;
                        }
                        
                        .main-stat-image
                        {
                            margin-top: 10px;
                            margin-bottom: auto;
                            width: 40px;
                            height: 40px;
                            -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white);
                        }

                        .name{
                            font-size: 12px;
                        }
                    }
                }
                
                .skill-container
                {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .skill{
                        margin: 5px;
                        flex: 0 0 20%;
                        padding-bottom: 10px;
                        -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);
                        .skill-image
                        {
                            margin-top: auto;
                            margin-bottom: auto;
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                .stat-container
                {   
                    display: flex;
                    justify-content: space-between;

                    .stat{
                        width: 100%;
                        -webkit-box-shadow: 2px 4px 10px 5px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 5px rgba(201, 201, 201, 0.47);
                        padding: 3px;
                        margin-left: 3px;
                        margin-right: 3px;
                        
                        .stat-image
                        {
                            margin-top: auto;
                            margin-bottom: auto;
                            width: 25px;
                            height: 25px;
                        }

                        .name{
                            font-size: 12px;
                        }

                        .effect-image{
                            width: 40px;
                            height: 40px;
                        }
                        
                        .effect{
                            font-size: 14px;
                        }
                    }
                }


                .true{
                    -webkit-filter: drop-shadow(0px 0px 3px yellow) drop-shadow(0px 0px 1px red);
                }

                .eidolon{
                    display: flex;
                    flex-wrap: wrap;

                    .recommended-0 {
                        filter: grayscale(100%) brightness(30%);
                    }

                    .recommended-1{
                        img{
                            -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 5px white);
                        };
                    }
                    
                    .eidolons{

                        img{
                            width: 60px;
                            height: 60px;
                        }
                    }
                }

                .innerRows {
                    display: flex;
                    padding-bottom: 5px;

                    .eidolon-description, .lightcone-description, .obtain{
                        font-size: 14px;
                        text-align: left;
                        margin-left: 5px;
                    }
                    
                    .barchart{
                        width: 100%;
                    }

                    .stats{
                        width: 100%;
                        display: flex;
                        .stat{
                            display: flex;
                            margin-right: 20px;
                            font-size: 14px;
                            h4{
                                margin-top: 6px;
                            }

                            .icon{
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }

                    .score{
                        display: flex;
                        
                        .circle-I{
                            color: #CCAF84;
                            background: black;
                        }
                        
                        .circle-V{
                            color: black;
                            background: #FFD472;
                        }

                        .circle-I, .circle-V{
                            margin: 5px;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            font-size: 13px;
                            z-index: 2;
                            -webkit-filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white);
                        }
                    }
                }

                .barchart {

                    .name {
                        text-align: left;
                        margin-left: 5px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }
                    
                    .name-section{
                        display: flex;

                        .icon{
                            margin-left: 5px;
                            margin-top: -3px;
                        }
                    }

                    .count {
                        margin-top: 5px;
                        background-color: blue;
                        padding-right: 3px;
                        padding-left: 3px;
                        margin-left: 5px;
                        position: relative;
                        text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                            #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;

                        background: linear-gradient(90deg, rgb(0, 50, 100) 0%, rgba(110, 141, 190, 1) 50%, rgba(154, 194, 191, 1) 100%);
                    }
                }

                .lightconeImage {
                    padding: 5px;
                    border-radius: 10px;
                    height: 50px;
                    width: 50px;
                    margin-right: 5px;
                    margin-left: -5px;
                }

                .relicImage {
                    width: 50px;
                    height: 50px;
                }

                .bonus{
                    white-space: pre-wrap;
                    font-size: 14px;
                    text-align: left;
                    margin-left: 5px;
                }
            }
        }

        .middle {
            .formula {
                text-align: center;
            }
        }

        .bottom {
            padding-left: 10px;
            padding-right: 10px;

            h2 {
                padding-top: 20px;
                text-align: center;
            }
        }

        .popupContainer {
            overflow-y: scroll;
            z-index: 999;
            position: fixed;
            width: 80%;
            padding-top: 20px;
            padding-left: 10%;
            padding-right: 10%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: black;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

            h2 {
                text-align: center;
            }

            h4 {
                height: fit-content;
            }

            .closeButton {
                transition: all 0.2s ease-in-out;
                position: absolute;
                top: -30px;
                right: 30px;
                font-size: 30px;
                cursor: pointer;
            }

            .closeButton:hover {
                -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white);
            }


            .closeBottomButton {
                display: flex;
                margin-top: 20px;
                justify-content: center;
                .button{
                    font-size: 20px;
                    text-align: center;
                    color: black;
                    background-color: white;
                }
                .button:hover{
                    background-color: rgb(192, 192, 192);
                }
            }

            .circle {
                font-weight: bolder;
                color: white;
                text-align: center;
                background: black;
                -webkit-filter: drop-shadow(0px 0px 15px white);
            }

            h1,
            h2,
            h3,
            h4 {
                white-space: nowrap;
                margin-block-start: 0;
                margin-block-end: 0;
            }

            .relics {
                
                display: flex;
                flex-wrap: wrap;

            
                @media (max-width: 650px) {
                    .relic {
                        flex: 0 0 100%;
                    }
                }
                
                @media  (min-width: 650px) and (max-width: 1000px)  {
                    .relic {
                        flex: 0 0 45%;
                    }
                }

                @media  (min-width: 1000px) {
                    .relic {
                        flex: 0 0 30%;
                    }
                }


                .relic {
                    display: flex;
                    flex-wrap: wrap;
                    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                    border-radius: 10px;
                    color: white;
                    width: 230px;
                    height: 270px;
                    margin-top: 15px;
                    margin-left: 10px;
                    margin-right: 10px;

                    .relicTop {
                        position: relative;

                        .relic-set {
                            position: relative;
                            width: 20px;

                            .relic-set-desc {
                                position: absolute;
                                left: 10px;
                                top: 10px;
                                padding: 10px 3px 10px 3px;
                                border-radius: 5px;
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);

                                h4 {
                                    -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                }
                            }
                        }

                        .relic-image {
                            width: 55px;
                            margin-top: 5px;
                            margin-left: 28px;
                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                        }

                        .level {
                            position: relative;
                            margin-left: 32px;
                            margin-top: -27px;
                            width: 35px;
                            border-radius: 10px;
                            padding: 0px 5px;
                            font-size: 16px;
                            background: linear-gradient(90deg, rgba(14, 20, 37, 1) 0%, rgba(10, 10, 10, 1) 50%, rgb(138, 110, 85) 100%);
                        }


                        .main-stat-container {
                            position: absolute;
                            width: 50px;

                            text-align: right;
                            top: 5px;
                            left: 85px;

                            .inner {
                                display: flex;

                                .main-stat {
                                    margin-right: 0px;
                                    width: 35px;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }

                                .main-value {
                                    position: relative;
                                    margin-top: 0px;
                                    padding-left: 10px;
                                    text-align: left;
                                    font-size: 22px;
                                    -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                                }
                            }

                            .description {
                                padding-left: 5px;
                                text-align: left;
                                -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                            }
                        }
                    }

                    .horizontal_line {
                        margin-top: 10px;
                        background: white;
                        position: relative;
                        -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                        height: 1px;
                        margin-left: 5px;
                        margin-right: 5px;
                        width: 100%;
                    }

                    .relic-score {
                        margin-top: 5px;
                        margin-bottom: -13px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        text-align: center;

                        .score-title {
                            -webkit-filter: drop-shadow(0px 0px 2px black) drop-shadow(0px 0px 2px black);
                        }

                        .value {
                            font-size: 30px;
                            text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                        }
                    }

                    .subproperties {
                        padding-top: 5px;
                        width: 100%;
                        height: 100%;

                        .subproperty {
                            width: 100%;
                            padding-left: 5px;
                            padding-bottom: 5px;
                            display: flex;

                            .inner {
                                width: 55%;
                                display: flex;

                                .description {
                                    padding-left: 5px;
                                    padding-top: 2px;
                                    -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                }

                                .sub-stat {
                                    height: 26px;
                                    -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);
                                }
                            }

                            .sub-value-container {
                                padding-top: 2px;
                                display: flex;
                                font-size: 17px;
                                text-align: right;
                                gap: 5px;
                                -webkit-filter: drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px black);

                                .empty-enhancement {
                                    padding-left: 3px;
                                    padding-right: 3px;
                                    visibility: hidden;
                                }

                                .enhancement {
                                    padding-left: 3px;
                                    padding-right: 3px;
                                    border-radius: 3px;
                                    background-color: rgb(89, 185, 89);
                                    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
                                        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
.character {
    width: 100%;
    margin-top: 70px;
    .characterBody {
        padding-top: 50px;

        .outer
        {
            .ranking_sections{
                box-shadow: inset 0 0 0 2000px rgba(24, 24, 24, 0.85);
                align-self: center;
                justify-content: center;
                text-align: center;
            }
        }

        h4{
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .sections {
            margin: 20px;
            align-self: center;
            justify-content: center;
            text-align: center;

            .title{
                height: fit-content;
                padding-top: 50px;
            }
            
            .subtitle{
                height: fit-content;
            }

            h3{
                margin-top: 10px;
                margin-bottom: 0px;
                height: fit-content;
            }

            .rowContainer
            {
                padding-bottom: 10px;
                margin-bottom: 10px;
                background-color: rgba(201, 201, 201, 0.3);

                .row {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .rowItems{                   
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        padding-top: 2px;
                        padding-bottom: 2px;
    
                        text-align: center;
                        vertical-align: middle;
                        
                        img{
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}
.datatable {
    user-select: none !important;

    .MuiDataGrid-cell{
        outline: none !important;
    }

    .MuiDataGrid-row{
        height: fit-content;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .search{
        transition: all 0.2s ease-in-out;
        -webkit-filter: drop-shadow(0px 0px 3px red) drop-shadow(0px 0px 5px red) ;
    }

    .search:hover{
        cursor: pointer;
        -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white) ;
    }

    .MuiDataGrid-overlay, .MuiDataGrid-columnHeader{
        background-color: rgba(128, 128, 128, 0.5);
        color: white !important;
    }

    .MuiInputBase-root, .MuiTablePagination-selectLabel, .MuiTouchRipple-root, .MuiDataGrid-columnSeparator{
        visibility: visible !important;
        color: white;
    }

    .MuiDataGrid-menuIconButton{
        right: 0;
        position: absolute;
    }

    .MuiDataGrid-selectedRowCount, .MuiTablePagination-selectLabel, .MuiTablePagination-select , .MuiTablePagination-selectIcon{
        visibility: collapse !important;
    }
    


    .MuiDataGrid-cellContent, .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiSvgIcon-root{
        color: white;
    }
    .highlight{
        background-color: rgba(0, 153, 255, 0.3);
    }

    .datatableTitle {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        font-size: 24px;
        color: white;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .lightcone
    {
        .image{
            margin-top: 10px;
            width: 40px;
            height: 40px;
            -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white) ;
        }

        .rank{
            margin-top: 15px;
            margin-left: 5px;
            margin-right: 5px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            border-radius: 50%;
            font-size: 15px;
            color: #c8ac80;
            text-align: center;
            background: #4d415c;
        }

        .V{
            color: black;
            background: #ffd473 !important;
        }
    }

    .single-relic-set{
        gap: 5px;
        padding-right: 5px;
        .image{
            margin-top: 10px;
            width: 40px;
            height: 40px;
            -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white) ;
        }

        
        .desc{
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 3px;
            color: white;
            text-shadow: #000000 0px 0px 3px , #000000 0px 0px 3px, #000000 0px 0px 3px, #000000 0px 0px 3px !important;
        }
    }

    .eidolon{
        .image{
            width: 23px;
            -webkit-filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 5px white) ;
        }
    }

    p{
        color: white;
    }

    .achievement
    {
        display: flex;
        gap:10px;

        .trophy{
            width: 25px;
            height: 28px;
            margin-top: 15px;
        }
    }
    .chaos
    {
        display: flex;
        gap:10px;

        .memoryOfChaos{
            width: 25px;
            height: 25px;
            margin-top: 15px;
        }
    }
    .property{
        .image{
            width: 40px;
            margin-left: -15px;
            object-fit: contain;
        }
    }
}
    .relic {
    width: 100%;
    margin-top: 70px;
    height: fit-content;
    

    .MuiDataGrid-footerContainer{
        visibility: collapse;
        margin-bottom: -50px;
    }

    .relic-body {
        padding-top: 50px;
        .relic-section{
            height: 900px;
        }
        padding: 20px;

        .title{
            height: fit-content;
        }
        
        .subtitle{
            padding-bottom: 20px;
            height: fit-content;
        }

        h3{
            padding-top: 20px;
            margin-bottom: 0px;
        }

        .sections {
            align-self: center;
            justify-content: center;
            text-align: center;

            .datatable{
                padding-right: 10px;
                padding-left: 10px;

                .datagrid{
                    height: 720px;
                }
            }

            .dataTable-footer
            {
                height: 95px;
                display: flex;
                justify-content: center;
                gap: 20px;

                .pagination{
                    cursor: pointer;
                }
            }


            .rowContainer
            {
                margin: 20px;
                padding-bottom: 10px;
                background-color: rgba(201, 201, 201, 0.3);

                .menuRow{
                    gap: 20px;
                }

                .row {
                    height: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 20px;

                    .menu {
                        position: relative;
                        border: 3px solid white;
                        border-radius: 10px;
                        width: 200px;

                        .menuSelection{
                            margin-bottom: -20px;
                            display: flex;
                            padding: 10px;
                            align-self: center;
                            justify-content: center;
                            text-align: center;
                            h4{
                                font-size: 20px;
                                margin-top: 20px;
                            }

                            .arrow{
                                cursor: pointer;
                                position: absolute;
                                top: 30px;
                                right: 3px;
                                font-size: 30px;
                            }

                            .arrow:hover{
                                border-radius: 20px;
                                background-color: gray;
                            }
                        }
                      }
                      
                      .menu:before {
                        content: attr(data-title);
                        background: white;
                        position: absolute;
                        padding: 0 20px;
                        color: black;
                        left: 0;
                        top: 0;
                      }

                      .menu ul {
                        margin: 0;
                      }
                      
                      .mainmenu{
                        z-index: 99;
                        visibility: collapse;
                        border-radius: 10px;
                        background-color: white;
                        padding: 0px;
                        width: 200px;
                        color: black;
                        position: absolute;

                        li{
                            padding-top: 10px;
                            padding-bottom: 10px;
                            list-style-type: none;
                        }

                        li:hover{
                            cursor: pointer;
                            background-color: gray;
                        }
                      }

                    .filter:hover, .filter.active, .relic-set:hover, .relic-set.active {
                        cursor: pointer;
                        scale: 120%;
                        -webkit-box-shadow: 2px 4px 10px 10px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 10px rgba(201, 201, 201, 0.47);
                        background-color: rgb(41, 41, 41);
                        transition: all 0.2s ease-in-out;
                    }

                    @media (max-width: 768px) {
                        .rowItems {
                            flex: 0 0 100px;
                        }
                    }
            
                    @media (min-width: 768px) and (max-width: 1200px) {
                        .rowItems {
                            flex: 0 0 150px;
                        }
                    }
            
                    @media (min-width: 1200px) {
                        .rowItems {
                            flex: 0 0 200px;
                        }
                    }
                    
                    .rowItems{       
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
   
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;

                        img{
                            padding-top: 10px;
                            margin-bottom: -10px;
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}